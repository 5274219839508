#footer {
  position: absolute;
  bottom: 0;
  height: $footer-hight;
  width: 100%;
  padding: 30px 0;
  background-color: $color-darkerblue;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    height: 80px;
    padding: 20px 0;
  }
  .site-logo {
    margin-top: 7px;

    img {
      width: 107px;
      height: 25px;
    }
  }
  .navbar-nav {
    display: block;
    font-size: 0;
    text-align: right;

    .nav-item {
      display: inline-block;
      width: 50%;
      text-align: right;

      a {
        font-size: 14px;
        color: $color-white;
        transition: ease-in-out .3s color;

        &:hover {
          color: $color-lightblue;
        }
      }
    }
  }
  .copyright {
    margin-top: 10px;
    text-align: center;

    p {
      font-size: 14px;
      color: $color-white;
    }
    a {
      padding-bottom: 2px;
      color: $color-lightgreen;
      border-bottom: thin solid $color-lightgreen;

      &:hover {
        text-decoration: none;
        color: $color-lightblue;
        border-color: $color-lightblue;
      }
    }
  }
}
