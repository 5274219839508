$logo-height: 32px;
$logo-width: 138px;

$navbar-height: 62px;

$footer-hight: 146px;

$color-darkgreen: #234324;
$color-lightgreen: #DCF797;
$color-white: #FFFFFF;
$color-blue: #383FDA;
$color-darkblue: #505271;
$color-darkerblue: #323353;
$color-lightblue: #7D84A1;
$color-lighterblue: #9297FC;
$color-lightgray: #E9ECF3;

$gradient-color-1: #2A2B5F;
$gradient-color-2: #3237A8;

$font-sourcesans: 'Source Sans Pro', sans-serif;
$font-ubuntu: 'Ubuntu', sans-serif;
