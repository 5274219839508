html, body {
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
html {
  height: 100%;
}
body {
  position: relative;
  padding-bottom: $footer-hight;
  font-family: $font-ubuntu;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;

  @include media-breakpoint-up(lg) {
    padding-bottom: 80px;
  }
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0.1rem;
}
h1, h3{
  font-family: $font-sourcesans;
}
h2, h4, h5, h6 {
  font-family: $font-ubuntu;
}
h1, h2, h3 {
  font-weight: bold;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 22px;
}

.site-logo {
  display: block;
  height: $logo-height;
  width: $logo-width;
}

.jumbotron {
  padding: 20px 0;

  &-title {
    font-family: $font-sourcesans;
    font-size: 44px;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0.025rem;
  }
  &-text {
    margin-top: 20px;
    font-family: $font-sourcesans;
    font-size: 20px;
    line-height: 30px;
  }
  &-buttons {
    margin-top: 35px;

    a {
      display: inline-block;
      width: auto;
      margin-right: 20px;
    }
  }
}

.btn {
  padding: 8px 8px;
  font-family: $font-sourcesans;
  font-size: 14px;
  letter-spacing: 0.1rem;
  line-height: 24px;
  text-transform: uppercase;
  border: 2px solid transparent;
  box-shadow: 1px 2px 5px 0 rgba(38, 38, 66, 0.4);

  &-sitediff-white {
    background-color: $color-white;
    color: $color-blue;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
      border-color: transparent;
    }
  }
  &-sitediff-blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-blue;
      border-color: transparent;
    }
  }
  &-sitediff-transparent {
    background-color: transparent;
    color: $color-white;
    border: 2px solid $color-lightblue;

    &:hover {
      background-color: $color-white;
      color: $color-blue;
      border-color: $color-white;
    }
  }
}

.bg-light-grey {
  background-color: $color-lightgray;
}

// Icons
@font-face {
  font-family: 'icons';
  src: url('../font/icons.eot?54043623');
  src: url('../font/icons.eot?54043623#iefix') format('embedded-opentype'),
  url('../font/icons.woff?54043623') format('woff'),
  url('../font/icons.ttf?54043623') format('truetype'),
  url('../font/icons.svg?54043623#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
