#header {
  position: relative;
  background: linear-gradient(219.83deg, $gradient-color-1 0%, $gradient-color-2 100%);

  .navbar {
    height: $navbar-height;
    padding: 0;
  }
  .navbar-nav {
    .nav-item {
      margin-left: 40px;

      a {
        padding: 0 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 62px;
        letter-spacing: 0.1rem;
        color: $color-white;
        opacity: 0.9;
      }
    }
  }
  .navbar-text {
    padding: 0;

    a {
      padding: 0 5px;
      font-size: 12px;
      font-weight: 400;
      line-height: 62px;
      letter-spacing: 0.1rem;
      color: $color-white;
      opacity: 0.9;
    }
  }
  .nav-link {
    border-top: 3px solid transparent;

    &:hover {
      border-color: $color-lighterblue;
      color: $color-lighterblue !important;
      transition: ease-in-out 0.3s all;
    }
  }
  @include media-breakpoint-down(md) {
    .navbar-toggler {
      height: 42px;
      padding: 5px;
      margin: 10px 0;
      border: none;
    }
    .navbar-collapse {
      position: absolute;
      right: 0;
      top: 62px;
      width: 200px;
      text-align: right;
      border-top: 3px solid $color-white;
      background: linear-gradient(219.83deg, $gradient-color-1 0%, $gradient-color-2 100%);
      z-index: 1;

      .nav-item {
        margin: 0 !important;
      }
      .navbar-text {
        display: block;
      }
      a {
        padding: 0 20px !important;
        margin: 0 !important;
        transition: ease-in-out .3s all;
        border-top: none;
        border-bottom: 2px solid transparent;
        transform: rotate(0);

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .ico-github:before {
    content: '\f09b';
    font-family: 'icons';
    font-size: 1.75em;
    font-style: normal;
    font-weight: normal;
    vertical-align: middle;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
